class SliderRegular extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.initSwiper();
  }

  initSwiper() {
    const swiperOptions = {
      slidesPerView: 'auto',
      spaceBetween: 12,
      freeMode: true,
      breakpoints: {
      	993: {
      		slidesPerView: 3,
      		spaceBetween: 30,
      		freeMode: false
      	}
      },
      navigation: {
		    nextEl: this.querySelector('.js_btn-next'),
		    prevEl: this.querySelector('.js_btn-prev'),
		  }
      // Add more Swiper options here if needed
    };

    const swiperContainer = this.querySelector('.swiper');
    if (swiperContainer) {
      new Swiper(swiperContainer, swiperOptions);
    } else {
      console.error("Swiper container not found within the component.");
    }
  }
}

customElements.define('slider-regular', SliderRegular);
